export const loginPages = {
  email: 'email',
  password: 'password',
  btnLogin: 'btnLogin',
  loginError: 'loginError',
  authenticationCode: 'authenticationCode'
};

export const mainPageIds = {
  buttonLogout: 'buttonLogout',
  switcherOrganization: 'switcherOrganization',
  inputSwitcherOrganization: 'inputSwitcherOrganization',
  mainMenuItem: (index) => `mainMenuItem${index}`,
  adminMenuItem: (index) => `adminMenuItem${index}`
};
export const itemMenuIds = {
  linkToSubPage: (index) => `linkToSubPage${index}`
};
export const contractsPageIds = {
  buttonNewContract: 'buttonNewContract',
  inputSearch: 'inputSearch',
  buttonDeleteTag: (index) => `buttonDeleteTag${index}`,
  buttonSearch: 'buttonSearch',
  dropdownAdvancedSearch: 'dropdownAdvancedSearch',
  selectStatus: 'selectStatus',
  dateInputStart: 'dateInputStart',
  dateInputEnd: 'dateInputEnd',
  dateInputStartCreateDate: 'dateInputStartCreateDate',
  dateInputEndCreateDate: 'dateInputEndCreateDate',
  linkToContract: (index) => `linkToContract${index}`,
  tagContractCard: (tagIndex, contractId) => `tag${tagIndex}contractCard${contractId}`,
  selectSearchPropName: 'selectSearchPropName',
  buttonSortById: 'buttonSortById',
  buttonSortByCreateDate: 'buttonSortByCreateDate',
  buttonSortByStartDate: 'buttonSortByStartDate'
};
export const contractViewPageIds = {
  buttonEdit: 'buttonEdit',
  headingContractTitle: 'headingContractTitle',
  divItemName: 'divItemName'
};
export const contractEditPageIds = {
  selectType: 'selectType',
  selectInputType: 'selectInputType',
  inputNumber: 'inputNumber',
  inputName: 'inputName',
  inputProjectName: 'inputProjectName',
  textareaComment: 'textareaComment',
  inputTags: 'inputTags',
  inputTagsBtnRemoveAll: 'inputTagsBtnRemoveAll',
  buttonRemoveTag: (value) => `buttonRemoveTag_${value}`,
  // buttonRemoveAllTags: 'buttonRemoveAllTags',
  dateInputStart: 'dateInputStart',
  dateInputEnd: 'dateInputEnd',
  selectStatus: 'selectStatus',
  dateInputStatus: 'dataInputStatus',
  inputStatusReason: 'inputStatusReason',
  contractCode1c: 'contractCode1c',

  //this list is identical for agent page
  inputBlockName: (index) => `inputBlockName${index}`,
  // inputBlockParamValue: (blockIndex, paramIndex) => `inputBlock${blockIndex}paramValue${paramIndex}`,
  inputBlockParamValue: (blockIndex, paramName) => `inputBlock${blockIndex}_${paramName}`,
  buttonBlockAddField: (index) => `buttonBlockAddField${index}`,
  buttonAddBlock: 'buttonAddBlock',
  buttonSave: 'buttonSave',
  buttonCancel: 'buttonCancel',
  checkboxListItem: (index) => `checkboxListItem${index}`,
  buttonModalCancel: 'buttonModalCancel',
  buttonModalCancelIco: 'buttonModalCancelIco',
  buttonModalAct: 'buttonModalAct',
  buttonDeleteBlock: (index) => `buttonDeleteBlock${index}`,
  buttonDeleteBlockParamValue: (blockIndex, paramIndex) => `buttonDeleteBlock${blockIndex}paramValue${paramIndex}`,
  ignoreGates: 'checkboxIgnoreGates',
};


export const agentsPageIds = {
  buttonNewAgent: 'buttonNewAgent',
  linkToNewAgent: 'linkToNewAgent',
  inputSearch: 'inputSearch',
  buttonDeleteTag: (index) => `buttonDeleteTag${index}`,
  buttonSearch: 'buttonSearch',
  dropdownAdvancedSearch: 'dropdownAdvancedSearch',
  selectStatus: 'selectStatus',
  dateInputStart: 'dateInputStart',
  dateInputEnd: 'dateInputEnd',
  linkToAgent: (index) => `linkToAgent${index}`,
  tagAgentCard: (tagIndex, agentId) => `tag${tagIndex}agentCard${agentId}`,
  selectSearchPropName: 'selectSearchPropName',
  buttonSortById: 'buttonSortById',
  buttonSortByCreateDate: 'buttonSortByCreateDate',
  agentName: 'agentName',
  agentStatus: 'agentStatus',
  agentCreateDate: 'agentCreateDate',
  terminationDate: 'terminationDate',
  terminationReason: 'terminationReason',
  agentTagsSelect: 'agentTagsSelect',
  agentTags: 'agentTags',
  agentCode: 'agentCode',
  agentInn1c: 'agentInn1c',
  agentName1c: 'agentName1c',
};
export const agentViewPageIds = {
  buttonEdit: 'buttonEdit',
  headingAgentTitle: 'headingAgentTitle'
};
export const agentEditPageIds = {
  inputName: 'inputName',
  inputTags: 'inputTags',
  inputTagsBtnRemoveAll: 'inputTagsBtnRemoveAll',
  buttonRemoveTag: (value) => `buttonRemoveTag_${value}`,
  // buttonRemoveAllTags: 'buttonRemoveAllTags',
  selectStatus: 'selectStatus',
  inputAgentCode: 'inputAgentCode',
  inputInn1c: 'inputInn1c',
  inputName1c: 'inputName1c',
  dateInputStatus: 'dataInputStatus',
  inputStatusReason: 'inputStatusReason',
  buttonAddMerchantIdSource: 'buttonAddMerchantIdSource',
  buttonAddManagerIdSource: 'buttonAddManagerIdSource',
  buttonAddProcessorIdSource: 'buttonAddProcessorIdSource',
  selectMerchantIdSource: (index) => `selectMerchantIdSource${index}`,
  selectManagerIdSource: (index) => `selectManagerIdSource${index}`,
  selectProcessorIdSource: (index) => `selectProcessorIdSource${index}`,
  inputMerchantIds: (index) => `inputMerchantIds${index}`,
  inputManagerIds: (index) => `inputManagerIds${index}`,
  inputProcessorIds: (index) => `inputProcessorIds${index}`,
};
export const groupFinTermViewPageIds = {
  buttonNewGroupFinTerm: 'buttonNewGroupFinTerm',
  buttonDeleteGroupFinTerm: (index) => `buttonDeleteGroupFinTerm${index}`,
  linkToGroupFinTermHistory: (index) => `linkToGroupFinTermHistory${index}`,
  buttonEditGroupFinTerm: (index) => `buttonEditGroupFinTerm${index}`,
  linkToGroupFinTerm: (index) => `linkToGroupFinTerm${index}`
};
export const finTermViewIds = {
  buttonEdit: 'buttonEdit'
};
export const actTemplateViewIds = {
  buttonEdit: 'buttonEdit'
};
export const groupFinTermEditPageIds = {
  headingGroupFinTermTitle: 'headingGroupFinTermTitle',
  inputName: 'inputName',
  inputHiddenGroupFinTermId: 'inputHiddenGroupFinTermId',
  groupFinTermName: 'groupFinTermName',
  selectPayer: 'selectPayer',
  selectInputPayer: 'selectInputPayer',
  selectPayee: 'selectPayee',
  selectInputPayee: 'selectInputPayee',
  selectPayerMerchant: 'selectPayerMerchant',
  selectInputPayerMerchant: 'selectInputPayerMerchant',
  selectPayeeMerchant: 'selectPayeeMerchant',
  selectInputPayeeMerchant: 'selectInputPayeeMerchant',
  selectCurrency: 'selectCurrency',
  selectInputCurrency: 'selectInputCurrency',
  selectCustomFunction: 'selectCustomFunction',
  selectInputCustomFunction: 'selectInputCustomFunction',
  switcherPayoutPeriod: 'switcherPayoutPeriod',
  selectSource: 'selectSource',
  selectInputSource: 'selectInputSource',
  selectType: 'selectType',
  selectInputType: 'selectInputType',
  nomenNumber1c: 'nomenNumber1c',
  checkboxIgnoreInExport: 'checkboxIgnoreInExport',
  checkboxApplyCustomFunction: 'checkboxApplyCustomFunction',
  inputSourceParam: (paramName) => `inputSourceParam_${paramName}`,
  selectInputSourceParam: (paramName) => `selectInputSourceParam_${paramName}`,
  buttonAddFinTerm: 'buttonAddFinTerm',
  buttonDeleteFinTerm: (index) => `buttonDeleteFinTerm${index}`,
  startDate: (index) => `startDate${index}`,
  endDate: (index) => `endDate${index}`,
  inputMinRate: (index) => `inputMinRate${index}`,
  selectAddAgreementOrContract: (index) => `selectAddAgreementOrContract${index}`,
  selectInputAddAgreementOrContract: (index) => `selectInputAddAgreementOrContract${index}`,
  buttonAddFinTermRow: (index) => `buttonAddFinTermRow${index}`,
  finTermParam: (finTermIndex, finTermParamsRowIndex, paramName) => `finTerm${finTermIndex}paramsRow${finTermParamsRowIndex}${paramName}`,
  buttonDeleteFinTermRow: (finTermIndex, finTermParamsRowIndex) => `buttonDeleteFinTerm${finTermIndex}Row${finTermParamsRowIndex}`,
  selectGateTerminals: (finTermGateIndex) => `selectGate${finTermGateIndex}Terminals`
};
export const contractAgentsPageIds = {
  buttonAddRelatedAgent: 'buttonAddRelatedAgent',
  linkToAgent: (index) => `linkToAgent${index}`,
  buttonDeleteAgent: (index) => `buttonDeleteAgent${index}`,
  buttonEditAgent: (index) => `buttonEditAgent${index}`,
  tileIconFintermsCount: (index) => `tileIconFintermsCount${index}`
};
export const documentsPageIds = {
  buttonAddDocument: 'buttonAddDocument',
  buttonUploadDocument: 'buttonUploadDocument',
  buttonDeleteUploadedDocument: (index) => `buttonDeleteUploadedDocument${index}`,
  inputUploadedDocumentDescription: (index) => `inputUploadedDocumentDescription${index}`,
  buttonDownloadDocument: (index) => `buttonDownloadDocument${index}`,
  buttonEditDocument: (index) => `buttonEditDocument${index}`,
  buttonSubstituteDocument: (index) => `buttonSubstituteDocument${index}`,
  buttonDeleteDocument: (index) => `buttonDeleteDocument${index}`,
  buttonCancelEditDocument: (index) => `buttonCancelEditDocument${index}`,
  buttonSubmitEditDocument: (index) => `buttonSubmitEditDocument${index}`,
  inputDocumentDescription: (index) => `inputDocumentDescription${index}`,
  modalInputDocumentName: (index) => `modalInputDocumentName${index}`,
  modalInputDocumentDescription: (index) => `modalInputDocumentDescription${index}`,
  modalSelectDocumentFolder: (index) => `modalSelectDocumentFolder${index}`,
  selectUploadedDocumentFolder: (index) => `selectUploadedDocumentFolder${index}`,
  selectInputUploadedDocumentFolder: (index) => `selectInputUploadedDocumentFolder${index}`,
  folderEdit: `folderEdit`,
  folderDelete: `folderDelete`,
  folderLink: (index) => `folderLink${index}`,
  addFolder: 'addFolder',
  showAddFolderModal: 'showAddFolderModal',
  renameFolder: 'renameFolder',
  toggleAllFiles: 'toggleAllFiles',
  backLink: 'backLink',
};
export const actTemplatesViewPageIds = {
  buttonNewActTemplate: 'buttonNewActTemplate',
  linkToActTemplate: (index) => `linkToActTemplate${index}`,
  buttonGenerateReport: (index) => `buttonGenerateReport${index}`,
    buttonGenerateReport_: (actTemplateId) => `buttonGenerateReport_${actTemplateId}`,
  linkToActTemplateHistory: (index) => `linkToActTemplateHistory${index}`,
  buttonDeleteActTemplate: (index) => `buttonDeleteActTemplate${index}`,
  linkDownloadBaseDocument: (index) => `linkDownloadBaseDocument${index}`,
  linkDownloadAddendumTemplateFile: (index) => `linkDownloadAddendumTemplateFile${index}`,
  selectMonth: 'selectMonth',
  selectYear: 'selectYear',
  selectInputMonth: 'selectInputMonth',
  selectInputYear: 'selectInputYear',
  dateInputAct: 'dateInputAct',
  startDate: 'startDate',
  endDate: 'endDate',
  rateAmountTagInput: (index) => `rateAmountTagInput${index}`
};
export const actTemplatesEditPageIds = {
  headingActTemplateTitle: 'headingActTemplateTitle',
  inputHiddenActTemplateId: 'inputHiddenActTemplateId',
  inputActTemplateName: 'inputActTemplateName',
  checkboxIsVerified: 'checkboxIsVerified',
  inputTagExpression: (propName) => `inputTagExpression_${propName}`,
  inputReportFileNamePattern: 'inputReportFileNamePattern',
  selectGroupFinTerms: 'selectGroupFinTerms',
  selectInputGroupFinTerms: 'selectInputGroupFinTerms',
  buttonDeleteGroupFinTerm: (index) => `buttonDeleteGroupFinTerm${index}`,
  buttonUploadDocument: 'buttonUploadDocument',
  buttonUploadAddendumTemplateFile: 'buttonUploadAddendumTemplateFile',
  buttonDeleteAddendumTemplateFile: 'buttonDeleteAddendumTemplateFile',
  buttonDownloadDocument: `buttonDownloadDocument`,
  buttonDownloadAddendumTemplateFile: `buttonDownloadAddendumTemplateFile`,
  buttonGetConvertedTag: (templateParamName, groupFinTermIndex) => `buttonGetConvertedTag_${templateParamName}${groupFinTermIndex}`,
  buttonEditConvertedTag: (templateParamName, groupFinTermIndex) => `buttonEditConvertedTag_${templateParamName}${groupFinTermIndex}`,
  buttonDeleteConvertedTag: (templateParamName, groupFinTermIndex) => `buttonDeleteConvertedTag_${templateParamName}${groupFinTermIndex}`,
  linkToDownloadFileWithTags: 'linkToDownloadFileWithTags',
  linkToViewTags: 'linkToViewTags',
  inputTemplateParamTag: (blockIndex, paramIndex, subBlockIndex) => subBlockIndex === undefined ?
    `inputTemplateParamTag_${blockIndex}_${paramIndex}` : `inputTemplateParamTag_${blockIndex}_${subBlockIndex}_${paramIndex}`,
  inputTemplateParamTagAlias: (blockIndex, paramIndex, subBlockIndex) => subBlockIndex === undefined ?
    `inputTemplateParamTagAlias_${blockIndex}_${paramIndex}` : `inputTemplateParamTagAlias_${blockIndex}_${subBlockIndex}_${paramIndex}`,
  tagGroupFinTermTemplateParam: (templateParamName, groupFinTermIndex) => `tag_${templateParamName}${groupFinTermIndex}`,
  tagGroupFinTermTemplateParamAlias: (templateParamName, groupFinTermIndex) =>
    `tag_alias_${templateParamName}${groupFinTermIndex}`,
  convertedTagGroupFinTermTemplateParam: (templateParamName, groupFinTermIndex) =>
    `convertedTag_${templateParamName}${groupFinTermIndex}`,
  convertedTagGroupFinTermTemplateParamAlias: (templateParamName, groupFinTermIndex) =>
    `convertedTag_alias_${templateParamName}${groupFinTermIndex}`,
  buttonToCopyTag: (propName) => `buttonToCopyTag_${propName}`,
  selectConvertationDate: 'selectConvertationDate',
  selectInputConvertationDate: 'selectInputConvertationDate',
  selectConvertationSource: 'selectConvertationSource',
  selectInputConvertationSource: 'selectInputConvertationSource',
  selectConvertationCurrency: 'selectConvertationCurrency',
  selectInputConvertationCurrency: 'selectInputConvertationCurrency',
  selectCurrency: 'selectCurrency',
  selectInputCurrency: 'selectInputCurrency',
  switcherPayoutPeriod: 'switcherPayoutPeriod',
  textareaManualInput: (groupFinTermId)=> `textareaManualInput_groupFinTerm_${groupFinTermId}`
};
export const historyPageIds = {
  dateInputStart: 'dateInputStart',
  dateInputEnd: 'dateInputEnd',
  selectInitiator: 'selectInitiator'
};
export const paginationIds = {
  page: (pageNumber) => `page${pageNumber}`,
  buttonForward: 'buttonForward',
  buttonBack: 'buttonBack',
  count: (countNumber) => `count${countNumber}`
};
export const reportsPageIds = {
  dropdownAdvancedSearch: 'dropdownAdvancedSearch',
  inputSearch: 'inputSearch',
  buttonSearch: 'buttonSearch',
  selectReportStatus: 'selectReportStatus',
  selectReportAccountStatus: 'selectReportAccountStatus',
  dateInputStartReportPeriod: 'dateInputStartReportPeriod',
  dateInputEndReportPeriod: 'dateInputEndReportPeriod',
  dateInputStartCreateReport: 'dateInputStartCreateReport',
  dateInputEndCreateReport: 'dateInputEndCreateReport',
  selectUser: 'selectUser',
  selectContract: 'selectContract',
  selectActTemplate: 'selectActTemplate',
  selectPayer: 'selectPayer',
  selectPayee: 'selectPayee',
  separateFilterSelectPayer: 'separateFilterSelectPayer',
  separateFilterSelectPayee: 'separateFilterSelectPayee',
  separateFilterInputSelectPayer: 'separateFilterInputSelectPayer',
  separateFilterInputSelectPayee: 'separateFilterInputSelectPayee',
  selectInputUser: 'selectInputUser',
  linkToReport: (index) => `linkToReport${index}`,
  linkToDownloadReport: (index) => `linkToDownloadReport${index}`,
  linkToDownloadBaseForReport: (index) => `linkToDownloadBaseForReport${index}`,
  linkToDownloadAddendumForReport: (index) => `linkToDownloadAddendumForReport${index}`,
  linkToDownloadSkippedForReport: (index) => `linkToDownloadSkippedForReport${index}`,
  linkToDownloadCalculatedResultFileForReport: `linkToDownloadCalculatedResultFileForReport`,
  linkToDownloadCalculatedResultAddendumFileForReport: `linkToDownloadCalculatedResultAddendumFileForReport`,
  divReportStatus: (index) => `divReportStatus${index}`,
  headingCardReportId: (index) => `headingCardReportId${index}`,
  checkboxReport: (index) => `checkboxReport${index}`,
  divCheckedReportsNumber: 'divCheckedReportsNumber',
  buttonCheckAll: 'buttonCheckAll',
  buttonBulkUpdateStatus: 'buttonBulkUpdateStatus',
  buttonBulkDownload1C: 'buttonBulkDownload1C'
};

export const reportViewPageIds = {
  headingReportTitle: 'headingReportTitle',
  buttonUploadNewBaseDocument: (index) => `buttonUploadNewBaseDocument${index}`,
  linkToDownloadReport: `linkToDownloadReport`,
  linkToDownloadReportPdf: `linkToDownloadReportPdf`,
  linkToDownloadAddServiceReport: `linkToDownloadAddServiceReport`,
  linkToDownloadAddServiceReportPdf: `linkToDownloadAddServiceReportPdf`,
  linkToDownloadBaseForReport: `linkToDownloadBaseForReport`,
  spanReportStatus: 'spanReportStatus',
  buttonUpdateStatus: 'buttonUpdateStatus',
  buttonAutoAdjust: 'buttonAutoAdjust',
  buttonManualAdjust: 'buttonManualAdjust',
  selectUpdateStatus: 'selectUpdateStatus',
  inputCurrentDocNum: 'inputCurrentDocNum',
  checkboxShowCurrentDocNum: 'checkboxShowCurrentDocNum',
  dateInputAct: 'dateInputAct',
  spanReportTotal: 'spanReportTotal',
  spanRateHours: (groupFinTermId) => `spanRateHours${groupFinTermId}`,
  divHours: (groupFinTermId) => `divHours${groupFinTermId}`,
  spanRateAmount: (groupFinTermId) => `spanRateAmount${groupFinTermId}`,
  spanTotalAmount: (groupFinTermId) => `spanTotalAmount${groupFinTermId}`,
  divCount: (groupFinTermId) => `divCount${groupFinTermId}`,
  spanConvertedRateAmount: (groupFinTermId) => `spanConvertedRateAmount${groupFinTermId}`,
  spanConvertedTotalAmount: (groupFinTermId) => `spanConvertedTotalAmount${groupFinTermId}`,
  linkToReportContract: 'linkToReportContract'
};

export const organizationsPageIds = {
  dropdownAdvancedSearch: 'dropdownAdvancedSearch',
  selectStatus: 'selectStatus',
  headingCardOrganizationId: (index) => `headingCardOrganizationId${index}`,
  linkToOrganization: (index) => `linkToOrganization${index}`,
  buttonNewOrganization: 'buttonNewOrganization',
};
export const usersPageIds = {
  dropdownAdvancedSearch: 'dropdownAdvancedSearch',
  selectStatus: 'selectStatus',
  selectOrganization: 'selectOrganization',
  selectRole: 'selectRole',
  headingCardUserId: (index) => `headingCardUserId${index}`,
  linkToUser: (index) => `linkToUser${index}`,
  buttonNewUser: 'buttonNewUser',
  inputSearch: 'inputSearch',
  buttonSearch: 'buttonSearch',
};
export const userEditPageIds = {
  inputName: 'inputName',
  inputEmail: 'inputEmail',
  inputPhone: 'inputPhone',
  selectStatus: 'selectStatus',
  inputGlobalPermissions: 'inputGlobalPermissions',
  inputGlobalPermissionsBtnRemoveAll: 'inputGlobalPermissionsBtnRemoveAll'
};
export const userOrganizationsPageIds = {
  linkToEdit: (index) => `linkToEdit${index}`,
  linkToDelete: (index) => `linkToDelete${index}`,
  selectRole: 'selectRole',
  selectInputRole: 'selectInputRole',
  selectOrganization: 'selectOrganization',
  selectInputOrganization: 'selectInputOrganization',
  buttonAdd: 'buttonAdd'
};
export const userViewPageIds = {
  headingUserTitle: 'headingUserTitle',
  buttonEdit: 'buttonEdit',
  buttonSendLink: 'buttonSendLink',
  inputEmailActivationLink: 'inputEmailActivationLink',
  divEmailSentMsg: 'divEmailSentMsg'
};
export const organizationViewPageIds = {
  headingOrganizationTitle: 'headingOrganizationTitle'
};
export const organizationEditPageIds = {
  inputName: 'inputName',
  selectStatus: 'selectStatus',
  inputSources: 'inputSources',
  inputSourcesBtnRemoveAll: 'inputSourcesBtnRemoveAll',
  inputCurrency: 'inputCurrency',
  inputContractTypes: 'inputContractTypes',
  inputAgentLinkedTypes: 'inputAgentLinkedTypes',
  inputContractLinkedTypes: 'inputContractLinkedTypes',
  inputAddServiceTypes: 'inputAddServiceTypes',
  inputNotedTerminalTypes: 'inputNotedTerminalTypes',
  inputCurrencyBtnRemoveAll: 'inputCurrencyBtnRemoveAll',
  inputOrganizationCode: 'inputOrganizationCode',
  selectLocale: 'selectLocale',
  selectInputLocale: 'selectInputLocale',
  selectLinkedAgent: 'selectLinkedAgent',
  selectInputLinkedAgent: 'selectInputLinkedAgent'
};
export const myOrganizationsPageIds = {
  dropdownAdvancedSearch: 'dropdownAdvancedSearch',
  selectStatus: 'selectStatus',
  headingCardOrganizationId: (index) => `headingCardOrganizationId${index}`,
  linkToOrganization: (index) => `linkToOrganization${index}`,
  buttonNewOrganization: 'buttonNewOrganization',
};
export const organizationParamsPageIds = {
  inputParamName: 'inputParamName',
  inputParamLabel: 'inputParamLabel',
  selectParamType: 'selectParamType',
  selectInputParamType: 'selectInputParamType',
  linkToEdit: (paramIndex) => `linkToEdit${paramIndex}`,
  buttonAddContractParams: 'buttonAddContractParams',
  buttonAddAgentParams: 'buttonAddAgentParams'
};
export const editLinkedItemModalIds = {
  dateInputLinkDate: 'dateInputLinkDate',
  selectLinkType: 'selectLinkType',
  selectInputLinkType: 'selectInputLinkType',
  inputDescription: 'inputDescription'
};
export const contractLinkedContractsPageIds = {
  buttonDeleteContract: (index) => `buttonDeleteContract${index}`,
  buttonEditContract: (index) => `buttonEditContract${index}`,
  buttonAddLinkedContract: 'buttonAddLinkedContract'
};
export const filterIds = {
  selectFilter: 'selectFilter',
  buttonResetAllFilters: 'buttonResetAllFilters',
  buttonSetAllViewed: 'buttonSetAllViewed'
};
export const organizationDataHistoryIds = {
  linkToAction: (cardIndex, actionIndex) => `historyCard${cardIndex}_action${actionIndex}`,
  buttonCallActionLog: (cardIndex, actionIndex) => `historyCard${cardIndex}_callActionLog${actionIndex}`,
};
export const searchInOrgContextIds = {
  buttonShowSheet: 'buttonShowSheet',
  buttonHideSheet: 'buttonHideSheet',
  inputSearchInOrgContext: 'inputSearchInOrgContext'
};
export const addAgreementsPageIds = {
  buttonDeleteAddAgreement: (index) => `buttonDeleteAddAgreement${index}`,
  buttonEditAddAgreement: (index) => `buttonEditAddAgreement${index}`,
  buttonNewAddAgreement: 'buttonNewAddAgreement',
  modalAddAgreementName: (index) => `modalAddAgreementName${index}`,
  modalAddAgreementNumber: (index) => `modalAddAgreementNumber${index}`,
  modalAddAgreementDescription: (index) => `modalAddAgreementDescription${index}`,
  modalAddAgreementStartDate: (index) => `modalAddAgreementStartDate${index}`,
  modalAddAgreementEndDate: (index) => `modalAddAgreementEndDate${index}`
};
export const addServicesPageIds = {
  buttonNewAddService: 'buttonNewAddService',
  buttonDeleteAddService: (index) => `buttonDeleteAddService${index}`,
  buttonEditAddService: (index) => `buttonEditAddService${index}`,
  addServiceCardTotalAmount: (addServiceId, sheetIndex) => `addServiceCard_${addServiceId}_sheet${sheetIndex}_totalAmount`
};
export const addServiceEditPageIds = {
  inputName: 'inputName',
  inputNomenNumber1c: 'inputNomenNumber1c',
  inputProjectName: 'inputProjectName',
  selectUserId: 'selectUserId',
  selectInputUserId: 'selectInputUserId',
  selectPayer: 'selectPayer',
  selectInputPayer: 'selectInputPayer',
  selectPayee: 'selectPayee',
  selectInputPayee: 'selectInputPayee',
  selectPayoutPeriod: 'selectPayoutPeriod',
  selectInputPayoutPeriod: 'selectInputPayoutPeriod',
  selectActTemplateId: 'selectActTemplateId',
  selectInputActTemplateId: 'selectInputActTemplateId',
  dateInput: 'dateInput',
  checkboxIgnoreInExport: 'checkboxIgnoreInExport',
  checkboxIsDetachedInvoice: 'checkboxIsDetachedInvoice'
};
export const stepsNavigationButtonsIds = {
  buttonCancel: 'buttonCancel',
  buttonBack: 'buttonBack',
  buttonForward: 'buttonForward',
  buttonFinish: 'buttonFinish'
};
export const sheetIds = {
  selectCurrency: (sheetIndex) => `selectCurrency${sheetIndex}`,
  selectInputCurrency: (sheetIndex) => `selectInputCurrency${sheetIndex}`,
  selectSheetAddServiceType: (sheetIndex, calculatedItemIndex) => `selectSheet${sheetIndex}AddServiceType${calculatedItemIndex}`,
  selectInputSheetAddServiceType: (sheetIndex, calculatedItemIndex) => `selectInputSheet${sheetIndex}AddServiceType${calculatedItemIndex}`,
  buttonDeleteSheetCalculatedItem: (sheetIndex, calculatedItemIndex) => `buttonDeleteSheet${sheetIndex}CalculatedItem${calculatedItemIndex}`,
  buttonAddSheetCalculatedItem: (sheetIndex) => `buttonAddSheet${sheetIndex}CalculatedItem`,
  buttonDeleteSheet: (sheetIndex) => `buttonDeleteSheet${sheetIndex}`,
  inputSheetCalculatedItemPrice: (sheetIndex, calculatedItemIndex) => `inputSheet${sheetIndex}CalculatedItem${calculatedItemIndex}Price`,
  inputSheetCalculatedItemNumber: (sheetIndex, calculatedItemIndex) => `inputSheet${sheetIndex}CalculatedItem${calculatedItemIndex}Number`,
  divSheetCalculatedItemAmount: (sheetIndex, calculatedItemIndex) => `divSheet${sheetIndex}CalculatedItem${calculatedItemIndex}Amount`,
  buttonAddSheet: 'buttonAddSheet'
};
export const addServiceAllDataPageIds = {
  buttonEditAddServiceType: 'buttonEditAddServiceType',
  buttonEditAddServiceDocs: 'buttonEditAddServiceDocs',
  buttonEditAddServiceSheets: 'buttonEditAddServiceSheets',
  headingAddServiceName: 'headingAddServiceName',
  inputHiddenAddServiceId: 'inputHiddenAddServiceId',
  inputHiddenInternalTemplateId: 'inputHiddenInternalTemplateId'
};
export const agentGatesParamsPageIds = {
  buttonRequestPaynetGatesParams: 'buttonRequestPaynetGatesParams'
};
