//@flow
import * as React from 'react';
import {connect} from "react-redux";
import Can from "../../../../../../../services/permissions/Can";
import {documentsPageIds} from "../../../../../../../tests/testIds";
import text from "../../../../../../../services/localization/text";
import NModal from "../../../../../../../components/simple/Modals/NModal/NModal";
import DocumentUploaderContent
  from "../../../../../../../components/composed/ItemDocuments/DocumentUploader/DocumentUploaderContent";
import RenderOnReady from "../../../../../../../components/service/RenderOnReady/RenderOnReady";
import Text1 from "../../../../../../../components/simple/TextComponents/Text1/Text1";
import type {
  documentIndex,
  isSubstituteMode, itemFilesType,
  permittedAction
} from "../../../../../../../services/flowTypes/componentFlowTypes";
import api from "../../../../../../../services/apiInstance/api";
import type {contractId, fileDescription, fileId} from "../../../../../../../services/flowTypes/dataFlowTypes";
import apiErrorHandler from "../../../../../../../services/apiInstance/apiErrorHandler";
import itemFilesActions
  from "../../../../../../../store/organization/item/itemFiles/itemFilesActions";
import type {
  onAddItemFiles,
  onSubstituteItemFiles
} from "../../../../../../../services/flowTypes/propFnFlowTypes";
import BtnEditAct from "../../../../../../../components/simple/Buttons/BtnEditAct/BtnEditAct";
import CardBtn from "../../../../../../../components/simple/Buttons/CardBtn/CardBtn";

type Props = {
  permittedAction: permittedAction,
  contractId: contractId,
  onAddItemFiles: onAddItemFiles,
  isSubstituteMode?: isSubstituteMode,
  inheritedFileDescription?: fileDescription,
  inheritedFileId?: fileId,
  fileIndex?: documentIndex,
  onSubstituteItemFiles: onSubstituteItemFiles,
  itemFilesType: itemFilesType
};

class ItemFileUploader extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: true,
      errorObject: null,
      modalIsShown: false,
      documentsArr: []
    };
  }
  onDocumentsArrUpdate = (documentsArr) => {
    this.setState({documentsArr});
  };
  showModal = () => {
    this.setState({modalIsShown: true});
  };
  hideModal = () => {
    this.setState({
      modalIsShown: false,
      documentsArr: []
    });
  };
  uploadDoc = () => {
    if (this.state.documentsArr.length) {
      this.setState({isReadyToRender: false});
      let documentsArrLength = this.state.documentsArr.length;
      for (let i=0; i<this.state.documentsArr.length; i++) {
        /* eslint-disable no-loop-func */
        const formData = new FormData();
        formData.append('file', this.state.documentsArr[i].file);

        const docName = this.state.documentsArr[i].name;
        const docFileDescription = this.state.documentsArr[i].fileDescription;
        const docSize = this.state.documentsArr[i].size;
        const docCreateDate = this.state.documentsArr[i].createDate;

        api.uploadTempFile(formData)
          .then(response => {
            const newFile = {
              fileDescription: docFileDescription,
              name: docName,
              size: docSize,
              createDate: docCreateDate,
              tempFileId: response.data.id
            };
            if (this.props.isSubstituteMode) {
              newFile.fileId = this.props.inheritedFileId;
              const actionValue = {
                fileIndex: this.props.fileIndex,
                fileObject: newFile
              };
              console.log(actionValue);
              this.props.onSubstituteItemFiles(actionValue);
            }  else {
              this.props.onAddItemFiles(newFile);
            }
            documentsArrLength = documentsArrLength - 1;
            if (documentsArrLength === 0) {
              this.setState({isReadyToRender: true});
              this.hideModal();
            }
          })
          .catch(error => {
            this.setState({
              isReadyToRender: true,
              errorObject: apiErrorHandler(error)
            });
          });
      }
    }
  };
  render() {
    return (
      <>
        <Can do={'usePermission'} on={this.props.permittedAction}>
          {this.props.isSubstituteMode ? (
            <BtnEditAct addClasses='mr_2du' id={documentsPageIds.buttonSubstituteDocument(this.props.documentIndex)}
                   onClick={this.showModal}>
              {text.substituteDocument}</BtnEditAct>
          ) : (
            this.props.itemFilesType === 'addService' ?
              <CardBtn onClick={this.showModal} id={documentsPageIds.buttonAddDocument} addClasses={'mt_3du'}>{text.uploadDocument}</CardBtn> :
            <Text1 addClasses='Text1_link flex mt_1du' id={documentsPageIds.buttonAddDocument}
                   onClick={this.showModal}>
              {text.uploadDocument}</Text1>
          )}
        </Can>
        {this.state.modalIsShown ? (
          <NModal
            addClasses='NModal_ov'
            modalTitle={text.documentsModalTitle}
            modalCancelBtnText={text.cancel}
            modalActionBtnText={text.btnAdd}
            onModalCancelBtnClick={this.hideModal}
            onModalActionBtnClick={this.uploadDoc}
          >
            <DocumentUploaderContent
              documentsArr={this.state.documentsArr}
              onDocumentsArrUpdate={this.onDocumentsArrUpdate}
              isAddAgreementMode={true}
              // folderId={this.props.folderId}
              // selectOptions={this.state.selectOptions}
              // folderList={this.props.folderList}
              itemType={'contract'}
              itemId={this.props.contractId}
              isMultipleUpload={!this.props.isSubstituteMode}
              inheritedFileDescription={this.props.inheritedFileDescription}
            />
          </NModal>
        ) : null}
        <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}/>
      </>
    );
  }
}

// const mapStateToProps = state => {
//   return {
//     files: state.organization.item.contractMainData.files,
//   };
// };

const mapDispatchToProps = dispatch => {
  return {
    onAddItemFiles: (newFile) => dispatch({
      type: itemFilesActions.ITEM_FILES_ADD,
      value: newFile}),
    onSubstituteItemFiles: (newFile) => dispatch({
      type: itemFilesActions.ITEM_FILES_SUBSTITUTE,
      value: newFile})
  };
};

export default connect(null, mapDispatchToProps)(ItemFileUploader);
