//@flow
import * as React from 'react';
import type {groupFinTerm} from "../../../../../../services/flowTypes/dataFlowTypes";
import {connect} from "react-redux";
import text from "../../../../../../services/localization/text";

type Props = {
  finTermsList: Array<groupFinTerm>
};

const SkippedTransactionsWarning = (props: Props) => {
  const hasSkippedData = props.finTermsList && props.finTermsList.filter(s=>s.skippedDataFileName && s.skippedDataFileName.length).length > 0
  return (
    hasSkippedData ? (
      <div className={`AlertCard__header AlertCard__header_in_ReportTotalExpression AlertCard__header_REPORT-WARNING mt_1du`}>
        <div className="AlertCard__header-ico"/>
        <div className="AlertCard__header-title">
          {text.reportSkippedDataWarning}
        </div>
      </div>
      ) : null
  );
};

const mapStateToProps = state => {
  return {
    finTermsList: state.organization.item.reportMainData.finTermsList,
  };
};
export default connect(mapStateToProps, null)(SkippedTransactionsWarning);
