//@flow
import * as React from 'react';
import {reportsPageIds} from "../../../../../../../tests/testIds";
import type {groupFinTerm, organizationId, reportId} from "../../../../../../../services/flowTypes/dataFlowTypes";
import type {errorObject, isReadyToRender} from "../../../../../../../services/flowTypes/appFlowTypes";
import {connect} from "react-redux";
import text from "../../../../../../../services/localization/text";
import type {groupFinTermIndex} from "../../../../../../../services/flowTypes/componentFlowTypes";

type Props = {
  groupFinTerm: groupFinTerm,
  reportId: reportId,
  organizationId: organizationId,
  groupFinTermIndex?: groupFinTermIndex
};
type State = {
  errorObject: errorObject,
  isReadyToRender: isReadyToRender
};
class BaseAndAddendumDocDownload extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: true,
      errorObject: null
    };
  }
  render() {
    return (
      <div className={'BaseAndAddendumDocDownload'}>
        {this.props.groupFinTerm.fileName && this.props.groupFinTerm.fileName.length ? (
          <a id={reportsPageIds.linkToDownloadBaseForReport(this.props.groupFinTermIndex)}
             className={'BtnEditAct'}
             target={'_blank'} rel="noopener noreferrer"
             href={`/report/${this.props.reportId}/downloadReportBaseDocument/${this.props.groupFinTerm.fileId}/${this.props.groupFinTerm.fileName}?o_id=${this.props.organizationId}`}
          >
            {text.reportSourceDoc}
          </a>
        ) : null}
        {this.props.groupFinTerm.addendumFileName && this.props.groupFinTerm.addendumFileName.length ? (
          <a id={reportsPageIds.linkToDownloadAddendumForReport(this.props.groupFinTermIndex)}
             className={'BtnEditAct ml_2du'}
             target={'_blank'} rel="noopener noreferrer"
             href={`/report/${this.props.reportId}/downloadAddendumDocument/${this.props.groupFinTerm.addendumFileId}/${this.props.groupFinTerm.addendumFileName}?o_id=${this.props.organizationId}`}
          >
            {text.reportAddendumDoc}
          </a>
        ) : null}
        {this.props.groupFinTerm.skippedDataFileName && this.props.groupFinTerm.skippedDataFileName.length ? (
          <a id={reportsPageIds.linkToDownloadSkippedForReport(this.props.groupFinTermIndex)}
             className={'BtnEditAct ml_2du'}
             target={'_blank'} rel="noopener noreferrer"
             href={`/report/${this.props.reportId}/downloadReportDocument/${this.props.groupFinTerm.skippedDataFileId}/${this.props.groupFinTerm.skippedDataFileName}?o_id=${this.props.organizationId}`}
          >
            {text.reportSkippedDoc}
          </a>
        ) : null}
      </div>

      : null);
  }
}

const mapStateToProps = state => {
  return {
    organizationId: state.organization.organizationId
  };
};

export default connect(mapStateToProps, null)(BaseAndAddendumDocDownload);
