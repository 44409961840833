//@flow
import * as React from 'react';
import {reportViewPageIds} from "../../../../../../tests/testIds";
import type {
  parentComponentType,
  reportFileType,
  reportIndex
} from "../../../../../../services/flowTypes/componentFlowTypes";
import type {
  fileId,
  fileName,
  organizationId,
  reportDocNum,
  reportId
} from "../../../../../../services/flowTypes/dataFlowTypes";
import api from "../../../../../../services/apiInstance/api";
import createTempLinkToDownloadFile from "../../../../../../utils/createTempLinkToDownloadFile";
import type {errorObject, id, isReadyToRender} from "../../../../../../services/flowTypes/appFlowTypes";
import apiErrorHandler from "../../../../../../services/apiInstance/apiErrorHandler";
import RenderOnReady from "../../../../../../components/service/RenderOnReady/RenderOnReady";
import getDocumentType from "../../../../../../utils/getDocumentType";
import Text1 from "../../../../../../components/simple/TextComponents/Text1/Text1";
import text from "../../../../../../services/localization/text";
import './ReportCardDownloadDoc.css';
import SecText from "../../../../../../components/simple/TextComponents/SecText/SecText";
import Heading4 from "../../../../../../components/simple/TextComponents/Heading4/Heading4";
import NLabel from "../../../../../../components/simple/FormComponents/NLabel/NLabel";
import Can from "../../../../../../services/permissions/Can";
import TextOverflowHandler
  from "../../../../../../components/simple/Containers/TextOverflowHandler/TextOverflowHandler";
import {connect} from "react-redux";
import {permissionsStrValues} from "../../../../../../configData/permissionsStrValues";
import SkippedTransactionsWarning from "./SkippedTransactionsWarning";

type Props = {
  reportIndex: reportIndex,
  docNum: reportDocNum,
  addServiceReportFileId: fileId,
  addServiceReportFileName: fileName,
  fileId: fileId,
  fileName: fileName,
  reportId: reportId,
  parentComponentType: parentComponentType,
  reportFileType: reportFileType,
  idForTest: id,
  organizationId: organizationId,
  finTermsListLength?: number,
  addServicesListLength?: number
};
type configDataByReportFileType = {
  promise: Function,
  labelContent: React.Node
};
type State = {
  errorObject: errorObject,
  isReadyToRender: isReadyToRender,
  config: {
    report: configDataByReportFileType,
    export: configDataByReportFileType,
    addServiceReport: configDataByReportFileType
  }
};

const cleanSlashFromString = (str) => {
  return str.replace("/", "");
};

class ReportCardDownloadDoc extends React.Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isReadyToRender: true,
      errorObject: null,
      config: {
        report: {
          promise: api.getReportDocument,
          labelContent: text.reportNumber
        },
        export: {
          promise: api.getExportDocument,
          labelContent: text.exportFileLabel
        },
        addServiceReport: {
          promise: null, // because the direct link is used
          labelContent: text.addServiceReportFileLabel
        }
      }
    };
  }

  downloadSkippedTransactions = () => {
    const {
      finTermsList,
    } = this.props;

    if (!finTermsList || finTermsList.length === 0) {
      return
    }

    const skippedTransactions = finTermsList.filter((term) => term.skippedDataFileId && term.skippedDataFileName);

    if (skippedTransactions.length === 0) {
      return;
    }

    this.setState({isReadyToRender: false});
    const promise = api.getReportDocument

    skippedTransactions
      .forEach((async (term) => {
        try {
          const resp = await promise(this.props.reportId, term.skippedDataFileId, term.skippedDataFileName)
          this.setState({isReadyToRender: true});
          createTempLinkToDownloadFile(resp.data, term.skippedDataFileName);
        } catch (error) {
          this.setState({
            isReadyToRender: true,
            errorObject: apiErrorHandler(error)
          });
        }
      }));
  };

  defineByParentComponentType = () => {
    let reportCardDownloadDocClassName, Label, DocNum;
    const docNumContent = this.props.reportFileType === 'report' ? this.props.docNum : (
      <TextOverflowHandler addClasses={"TextOverflowHandler_mw-100"}>{this.props.fileName}</TextOverflowHandler>
    );
    switch (this.props.parentComponentType) {
      case 'card':
        if (this.props.reportFileType === 'addServiceReport') {
          return null;
        } else {
          reportCardDownloadDocClassName = 'flex_jc-sb';
          DocNum = (<Text1>{docNumContent}</Text1>);
          Label = (<SecText addClasses={'SecText_lh16 mb_1du'}>
            {this.state.config[this.props.reportFileType].labelContent}</SecText>);
          return {reportCardDownloadDocClassName, Label, DocNum};
        }
      case 'page':
        reportCardDownloadDocClassName = 'flex';
        DocNum = (<Heading4 addClasses={'Heading4_lh16'}>{docNumContent}</Heading4>);
        Label = (<NLabel addClasses={'mb_half-du'}>
          {this.state.config[this.props.reportFileType].labelContent}</NLabel>);
        return {reportCardDownloadDocClassName, Label, DocNum};
      default:
        console.error('Unknown type of param!');
    }
  };
  getDocument = (event: SyntheticKeyboardEvent<HTMLElement>, isPdf?: boolean, fileNameStr, fileId) => {
    this.setState({isReadyToRender: false});
    const promise = isPdf ? api.getReportDocumentPdf : this.state.config[this.props.reportFileType].promise;
    const fileName = isPdf ? fileNameStr.replace(/\.docx|\.xlsx|\.txt/i, '.pdf') : fileNameStr;
    const handledFileName = cleanSlashFromString(fileName);

    promise(this.props.reportId, fileId, handledFileName)
      .then(response => {
        this.setState({isReadyToRender: true});
        createTempLinkToDownloadFile(response.data, handledFileName);
        this.downloadSkippedTransactions()
      })
      .catch(error => {
        this.setState({
          isReadyToRender: true,
          errorObject: apiErrorHandler(error)
        });
      });
  };

  render() {
    const {reportCardDownloadDocClassName, Label, DocNum} = this.defineByParentComponentType();

    const isPdfReport = this.props.finTermsListLength && !this.props.addServicesListLength
    const fileNameType = this.props.fileName ? getDocumentType(this.props.fileName) : '';
    const onAddServiceClick = (e) => this.getDocument(
      e,
      false,
      this.props.addServiceReportFileName,
      this.props.addServiceReportFileId
    );
    const onReportClick = (e, isPdf) => this.getDocument(
      e,
      isPdf,
      this.props.fileName,
      this.props.fileId
    );

    return (
      <>
        <RenderOnReady isReadyToRender={this.state.isReadyToRender} errorObject={this.state.errorObject}/>
        {this.props.parentComponentType === 'card' ? (
          <>
            <div
              onClick={onAddServiceClick}
              className="ReportCard__docType ReportCard__docType_bottom-border"
            >
              {fileNameType}
            </div>
            {!isPdfReport && (
              <div className="ReportCard__docType" style={{cursor: "unset"}}/>
            )}
          </>
        ) : (
          this.props.fileName ? (
            <div className={"ReportCardDownloadDoc " + reportCardDownloadDocClassName}>
              <div className={'ReportCardDownloadDoc__fileName mr_2du'}>
                {Label}
                {DocNum}
              </div>
              <Can do={'usePermission'} on={permissionsStrValues.report.view}>
                <div className="ReportCardDownloadDoc__file-ico"
                     id={this.props.idForTest}
                     onClick={(e) => onReportClick(e, false)}
                >
                  <div title={text.downloadReport} className="ReportCardDownloadDoc__fileNameType">{fileNameType}</div>
                </div>
                {isPdfReport
                  && this.props.reportFileType === 'report'
                  && this.props.parentComponentType === 'page'
                  && (
                    <button className="ReportCardDownloadDoc__file-ico ml_2du"
                            id={reportViewPageIds.linkToDownloadReportPdf}
                            onClick={(e) => {
                              onReportClick(e,
                                true
                              )
                            }}
                    >
                      <div title={text.downloadReport} className="ReportCardDownloadDoc__fileNameType">{'pdf'}</div>
                    </button>
                  )}
              </Can>
            </div>
          ) : null
        )}
        <SkippedTransactionsWarning/>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    organizationId: state.organization.organizationId,
    finTermsList: state.organization.item.reportMainData.finTermsList,
  };
};

export default connect(mapStateToProps, null)(ReportCardDownloadDoc);
